import { Stack, StackProps, useTheme } from '@mui/material';
import { Link } from '@remix-run/react';

import { ArrowRight as IconArrowRight, BatteryCharging as IconBatteryCharging } from 'react-feather';

import { AButton } from '@allvit/allvit-toolkit';
import type { NodeProps } from '@crystallize/reactjs-components';
import type { CrystallizeImageProps } from '~/components/atoms/CrystallizeImage/types';
import Heading from '~/components/atoms/Heading';
import BlogTile from '~/components/molecules/BlogTile';

interface BlogRowProps extends StackProps {
    blogPosts: [
        {
            hero: {
                content: {
                    firstImage: CrystallizeImageProps;
                };
            };
            title: {
                content: {
                    text: string;
                };
            };
            lead: {
                content: {
                    json: NodeProps;
                };
            };
            name: string;
            createdAt: string;
            path: string;
            id: string;
        },
    ];
}

const BlogRow = ({ blogPosts, sx }: BlogRowProps) => {
    const theme = useTheme();

    return (
        <Stack sx={sx}>
            <Stack
                sx={{
                    mb: 6,
                    pb: 2,
                    borderBottom: theme.border.medium,
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <IconBatteryCharging color={theme.palette.text.primary} size={22} />
                    <Heading
                        component="h2"
                        variant="h6"
                        sx={{
                            ml: 3,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            minHeight: '40px',
                        }}
                    >
                        Siste fra Studiehjelpen
                    </Heading>
                </Stack>

                <AButton
                    component={Link}
                    to="/blogg"
                    variant="tertiary"
                    endIcon={<IconArrowRight size={18} color={theme.palette.cta.main} />}
                >
                    Alle artikler
                </AButton>
            </Stack>
            <Stack
                sx={{
                    display: 'grid',
                    alignItems: 'start',
                    gridTemplateColumns: { xs: '1fr', lg: '1fr 1fr' },
                    gridGap: '1.5rem',
                }}
            >
                {blogPosts.map((blog: any, i: number) => {
                    if (!blog) return null;
                    return (
                        <BlogTile
                            key={blog.node.id}
                            hideLeadin
                            blog={blog.node}
                            titleComponent="h3"
                            sx={{
                                display: i > 0 ? { xs: 'none', lg: 'block' } : 'block',
                            }}
                        />
                    );
                })}
            </Stack>
        </Stack>
    );
};

export default BlogRow;
