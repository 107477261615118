import { Box, Stack, Typography, useTheme } from '@mui/material';

import { Link, useFetcher, useLoaderData, useLocation } from '@remix-run/react';
import { FC, useEffect, useState } from 'react';
import { ArrowRight as IconArrowRight, Award as IconAward, Gift as IconGift, Star as IconStar } from 'react-feather';

import Heading from '~/components/atoms/Heading';
import { Books as BooksIcon } from '~/components/atoms/Icon';

import Topics from '~/components/organisms/Topics';

import OutsideLayout from '~/components/templates/Outside';
import { useCurrentUser } from '~/utils/user';

import globalConfig from '~/configs/global';

import FrontContainer from '~/components/molecules/FrontContainer';
import CoverFlow from './components/CoverFlow';

import { AButton } from '@allvit/allvit-toolkit';
import { AllvitPlussBanner, AllvitPlussBannerVariant } from '~/components/molecules/AllvitPlussBanner';
import TopBannerButton from '~/components/molecules/TopBannerButton';
import BookTypes from '~/types/app/book';
import { SubscriptionPlanProfileTypes } from '~/types/app/inventory';
import { getFunctionalCookieValue, setFunctionalCookieValue } from '~/utils/cookies';
import EmptyBookshelf from '../Bookshelf/components/EmptyBookshelf';
import BlogRow from './components/BlogRow';
import BookRow from './components/BookRow';
import { RecruitmentCampaignBanner } from './components/RecruitmentCampaignBanner';
import { SubscriptionExpiringModal } from './components/SubscriptionExpiringModal';
import { useDispatch } from 'react-redux';
import ui from '~/store/ui';

export default function IndexRoute() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { signedIn, user, subscriptionExpiresWithinTwoWeeks } = useCurrentUser();
    const {
        lineBanner,
        lastReadBooks,
        lastAcquiredBooks,
        plans,
        books,
        blogPosts,
        booksInSubs,
        isOrgUser,
        recruitedUsers,
    } = useLoaderData() as any;

    const [showSubscriptionModal, setShowSubscriptionModal] = useState<boolean>(false);
    const [usersSubscriptionBooks, setUsersSubscriptionBooks] = useState<Array<BookTypes>>([]);
    const userIsSubscriber = plans?.some(
        (el: SubscriptionPlanProfileTypes) => el.expires === null || new Date(el.expires) > new Date(),
    );

    const showRecruitmentCampaignBanner = !user.organisation.isOrganisationUser;

    const location = useLocation();

    const claimRewardFetcher = useFetcher();

    const carouselConfig: Record<string, Record<string, FC | boolean>> = {
        'Populære pensumbøker': {
            icon: IconStar,
            showPricingInformation: true,
        },
        'Prøv en gratis bok': {
            icon: IconGift,
            showPricingInformation: false,
        },
    };

    const view = lastAcquiredBooks.size < 3 ? 'veryFew' : 'normal';

    useEffect(() => {
        // Checks if the user has already seen the subscription modal
        if (subscriptionExpiresWithinTwoWeeks) {
            const lastReadSubAccess = lastReadBooks.items?.filter(
                (book: BookTypes) => book.meta.userHasSubscriptionAccess,
            );
            const lastAcquiredSubAccess = lastAcquiredBooks.items?.filter(
                (book: BookTypes) => book.meta.userHasSubscriptionAccess,
            );
            setUsersSubscriptionBooks([...lastReadSubAccess, ...lastAcquiredSubAccess, ...booksInSubs]);

            const subModalCookie = getFunctionalCookieValue('showSubscriptionModal');
            if (!subModalCookie) {
                setFunctionalCookieValue('showSubscriptionModal', {
                    value: true,
                    expires: Date.now() + globalConfig.subscriptionModalCookieExpiryTime,
                });
                setShowSubscriptionModal(true);
            } else if (subModalCookie?.expires && Date.now() > subModalCookie.expires) {
                setShowSubscriptionModal(true);
                setFunctionalCookieValue('showSubscriptionModal', {
                    value: true,
                    expires: Date.now() + globalConfig.subscriptionModalCookieExpiryTime,
                });
            } else if (subModalCookie?.value) {
                setShowSubscriptionModal(true);
            }
        }

        if (Array.isArray(lastReadBooks.items) && lastReadBooks.items.length > 0) {
            dispatch(ui.actions.setValue(lastReadBooks.items?.slice(0, 3), ['leftVerticalNavbar', 'books']));
        }
    }, []);

    return (
        <>
            {!userIsSubscriber && lineBanner && lineBanner.label && lineBanner.url && (
                <TopBannerButton label={lineBanner.label} to={lineBanner.url} />
            )}
            <OutsideLayout signedIn={signedIn}>
                {lastReadBooks.total > 0 && (
                    <FrontContainer maxWidth="xxl">
                        <Stack
                            sx={{
                                w: '100%',
                                mb: { xs: 12, md: 18 },
                                flexDirection: { xs: 'column', lg: 'row' },
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Stack sx={{ mb: { xs: 6, lg: 0 } }}>
                                <Heading component="h2" sx={{ mb: 3 }}>
                                    {user.firstName && !isOrgUser ? (
                                        <>
                                            <Typography
                                                sx={{
                                                    display: 'inline',
                                                    fontSize: 'inherit',
                                                    fontFamily: 'inherit',
                                                    fontWeight: 'inherit',
                                                }}
                                            >
                                                Hei,{' '}
                                            </Typography>
                                            {user.firstName}
                                            <Typography
                                                sx={{
                                                    display: 'inline',
                                                    fontSize: 'inherit',
                                                }}
                                            >
                                                👋
                                            </Typography>
                                        </>
                                    ) : (
                                        <Typography
                                            sx={{
                                                display: 'inline',
                                                fontSize: 'inherit',
                                                fontFamily: 'inherit',
                                                fontWeight: 'inherit',
                                            }}
                                        >
                                            Hei&nbsp;👋
                                        </Typography>
                                    )}
                                </Heading>
                                <Typography variant="p1" sx={{ display: 'block', maxWidth: '550px' }}>
                                    Hva med å prøve en{' '}
                                    <AButton
                                        variant="link"
                                        component={Link}
                                        to="/sok/boker"
                                        state={{ from: location.pathname }}
                                    >
                                        ny bok
                                    </AButton>{' '}
                                    i dag?
                                </Typography>
                            </Stack>
                            <AllvitPlussBanner
                                variant={AllvitPlussBannerVariant.Dashboard}
                                userIsSubscriber={userIsSubscriber}
                                setShowSubModal={setShowSubscriptionModal}
                            />
                        </Stack>
                        {view === 'veryFew' && lastReadBooks && (
                            <Stack
                                sx={{
                                    mb: 18,
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    alignItems: 'stretch',
                                }}
                            >
                                <Stack
                                    sx={{
                                        pr: { xs: 0, sm: 3, md: 6, lg: 9 },
                                        mb: { xs: 8, sm: 0 },
                                        flexGrow: '1',
                                        width: { xs: '100%', sm: '66%', lg: '35%' },
                                    }}
                                >
                                    <BookRow
                                        books={lastReadBooks.items}
                                        icon={<BooksIcon sx={{ fontSize: 20, color: theme.palette.text.primary }} />}
                                        heading="Bokhylla di"
                                        showPricingInformation={false}
                                    />
                                </Stack>

                                <Stack
                                    sx={{
                                        pl: { xs: 0, sm: 3, md: 6, lg: 9 },
                                        width: { xs: '100%', sm: '35%', lg: '65%' },
                                    }}
                                >
                                    <BlogRow blogPosts={blogPosts} />
                                </Stack>
                            </Stack>
                        )}
                        {view !== 'veryFew' && lastReadBooks && (
                            <BookRow
                                books={lastReadBooks.items}
                                icon={<BooksIcon sx={{ fontSize: 20, color: theme.palette.text.primary }} />}
                                heading="Sist lest i bokhylla di"
                                moreButton={
                                    <AButton
                                        component={Link}
                                        to="/bokhylle"
                                        variant="tertiary"
                                        endIcon={<IconArrowRight size={18} color={theme.palette.cta.main} />}
                                    >
                                        Hele bokhylla di
                                    </AButton>
                                }
                                sx={{ mb: { xs: 12, sm: 18 } }}
                                showPricingInformation={false}
                            />
                        )}
                        {showRecruitmentCampaignBanner && (
                            <RecruitmentCampaignBanner
                                recruitedUsersNumber={recruitedUsers.length}
                                fetcher={claimRewardFetcher}
                                claimRewardRoute={'/'}
                            />
                        )}
                        {view !== 'veryFew' && lastAcquiredBooks && (
                            <Stack
                                sx={{
                                    mb: { xs: 12, sm: 18 },
                                    flexDirection: view !== 'normal' ? 'column' : { xs: 'column', sm: 'row' },
                                    alignItems: view !== 'normal' ? 'center' : 'stretch',
                                }}
                            >
                                <Stack
                                    sx={{
                                        pr: { xs: 0, sm: 3, md: 6, lg: 9 },
                                        mb: { xs: 12, sm: 0 },
                                        flexGrow: '1',
                                        width: { xs: '100%', sm: '50%', lg: '30%' },
                                    }}
                                >
                                    <CoverFlow minHeight={360} books={lastAcquiredBooks.items} />
                                </Stack>

                                <Stack
                                    sx={{
                                        pl: view !== 'normal' ? 0 : { xs: 0, sm: 3, md: 6, lg: 9 },
                                        width: { xs: '100%', sm: '50%', lg: '70%' },
                                    }}
                                >
                                    <BlogRow blogPosts={blogPosts} />
                                </Stack>
                            </Stack>
                        )}

                        {books &&
                            books.map(
                                (carouselBooks: {
                                    key: string;
                                    title: string;
                                    to: string;
                                    books: Array<BookTypes>;
                                }) => {
                                    const PrefixIcon: FC<{ color: string; size: number }> =
                                        (carouselConfig[carouselBooks.title]?.icon as FC) || null;
                                    return (
                                        <BookRow
                                            key={carouselBooks.key}
                                            icon={
                                                PrefixIcon && (
                                                    <PrefixIcon color={theme.palette.text.primary} size={20} />
                                                )
                                            }
                                            books={carouselBooks.books}
                                            heading={carouselBooks.title}
                                            moreButton={
                                                carouselBooks.to ? (
                                                    <AButton
                                                        component={Link}
                                                        to={carouselBooks.to}
                                                        variant="tertiary"
                                                        endIcon={
                                                            <IconArrowRight size={18} color={theme.palette.cta.main} />
                                                        }
                                                    >
                                                        Vis flere bøker
                                                    </AButton>
                                                ) : null
                                            }
                                            sx={{ mb: { xs: 12, sm: 18 } }}
                                            showPricingInformation={
                                                carouselConfig[carouselBooks.title]?.showPricingInformation as boolean
                                            }
                                        />
                                    );
                                },
                            )}
                        <BookRow
                            books={booksInSubs}
                            icon={<IconAward color={theme.palette.text.primary} size={20} />}
                            heading="Nyeste i Allvit+"
                            moreButton={
                                <AButton
                                    component={Link}
                                    to="/sok/boker?allvitPluss=1&gratis=1"
                                    state={{ from: location.pathname }}
                                    variant="tertiary"
                                    endIcon={<IconArrowRight size={18} color={theme.palette.cta.main} />}
                                >
                                    Alle bøker i Allvit+
                                </AButton>
                            }
                            sx={{ mb: { xs: 12, sm: 18 } }}
                            showPricingInformation={false}
                        />
                    </FrontContainer>
                )}
                {lastReadBooks.total > 0 && (
                    <FrontContainer maxWidth="xxl" sx={{ mb: { xs: 12, md: 18 }, alignItems: 'flex-start' }}>
                        <Box
                            sx={{
                                width: 1,
                                borderRadius: { xs: 0, lg: theme.radius.allXLarge },
                            }}
                        >
                            <Stack
                                sx={{
                                    mb: 6,
                                    pb: 2,
                                    borderBottom: theme.border.medium,
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Heading
                                    component="h2"
                                    variant="h6"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        minHeight: '40px',
                                    }}
                                >
                                    Finn bøker basert på ditt fagområde eller studie
                                </Heading>
                            </Stack>
                            <Topics
                                showEmojis
                                columns={3}
                                size="large"
                                topics={globalConfig.topics}
                                excludeTopics={['Annet']}
                            />
                        </Box>
                    </FrontContainer>
                )}
                {lastReadBooks.total < 1 && (
                    <EmptyBookshelf userIsSubscriber={userIsSubscriber} isOrgUser={isOrgUser} />
                )}
            </OutsideLayout>
            {showSubscriptionModal && (
                <SubscriptionExpiringModal
                    usersSubscriptionBooks={usersSubscriptionBooks}
                    setShowSubscriptionModal={setShowSubscriptionModal}
                />
            )}
        </>
    );
}
