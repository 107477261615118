import { AButton } from '@allvit/allvit-toolkit';
import { Box, Stack, StackProps, SxProps, Theme, useTheme } from '@mui/material';

import { Link } from '@remix-run/react';
import { useState } from 'react';
import { ChevronLeft as IconChevronLeft, ChevronRight as IconChevronRight } from 'react-feather';

import Heading from '~/components/atoms/Heading';
import BookTypes from '~/types/app/book';
import { getBookImageUrl } from '~/utils/book';
import { mergeSx } from '~/utils/mui';

interface CoverFlowProps extends StackProps {
    books: Array<BookTypes>;
}

const CoverFlow = ({ books, sx, ...props }: CoverFlowProps) => {
    const theme = useTheme();
    const [activeCover, setActiveCover] = useState(0);

    const getPose = (index: number): SxProps<Theme> => {
        const poses = [
            {
                transform: 'translateX(-50%) scale(1.3)',
                boxShadow: theme.shadows[2],
                opacity: '0',
                zIndex: '5',
                left: '-100%',
            },
            {
                transform: 'translateX(-50%) scale(1.2)',
                boxShadow: theme.shadows[2],
                opacity: '0',
                zIndex: '5',
                left: '-100%',
            },
            {
                transform: 'translateX(-50%) scale(1.1)',
                boxShadow: theme.shadows[2],
                opacity: '0',
                zIndex: '4',
                left: '40%',
            },
            {
                transform: 'translateX(-50%) scale(1)',
                boxShadow: theme.shadows[3],
                opacity: '1',
                zIndex: '3',
                left: '50%',
            },
            {
                transform: 'translateX(-50%) scale(0.9)',
                boxShadow: theme.shadows[2],
                opacity: '0.9',
                zIndex: '2',
                left: '60%',
            },
            {
                transform: 'translateX(-50%) scale(0.8)',
                boxShadow: theme.shadows[2],
                opacity: '0.6',
                zIndex: '1',
                left: '70%',
            },
            {
                transform: 'translateX(-50%) scale(0.7)',
                opacity: '0',
                zIndex: '0',
                left: '80%',
            },
        ];

        let pIndex = index - activeCover + 3;

        if (pIndex < 0) {
            pIndex = 0;
        } else if (pIndex > 6) {
            pIndex = 6;
        }

        return {
            height: 250,
            width: 'auto',
            borderRadius: theme.radius.all,
            transition: 'all 0.3s ease-in-out',
            position: 'absolute',
            top: '10px',
            ...poses[pIndex],
        };
    };

    return (
        <Stack
            sx={mergeSx(
                {
                    py: 8,
                    flexGrow: '1',
                    borderRadius: theme.radius.allLarge,
                    backgroundColor:
                        theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.background.darker,
                    alignItems: 'center',
                },
                sx,
            )}
            {...props}
        >
            <Heading
                color="secondary.dark"
                component="h2"
                variant="h6"
                sx={{ mb: 6, textAlign: 'center', color: theme.palette.common.white }}
            >
                Sist lagt til i bokhylla di
            </Heading>
            <Box
                sx={{
                    py: 1,
                    mb: 6,
                    width: '100%',
                    height: 280,
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                {books.map((book, i) => (
                    <Box
                        key={book.isbn}
                        loading="lazy"
                        component="img"
                        onError={({ currentTarget }) => {
                            currentTarget.src = getBookImageUrl(book, true);
                        }}
                        sx={getPose(i)}
                        src={book.imageUrl}
                        alt=""
                    />
                ))}
            </Box>
            <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                {activeCover > 0 ? (
                    <AButton
                        center
                        variant="secondary"
                        overrideColor={theme.palette.common.white}
                        startIcon={<IconChevronLeft color={theme.palette.common.white} size={20} />}
                        sx={{
                            p: 0,
                            minWidth: 'auto',
                            width: theme.spacing(10),
                            '& .MuiButton-startIcon': { mr: 0 },
                        }}
                        onClick={() => {
                            setActiveCover(activeCover - 1);
                        }}
                    />
                ) : (
                    <Box sx={{ width: theme.spacing(10) }} />
                )}
                <AButton variant="primary" sx={{ mx: 4 }} component={Link} to={`${books[activeCover].meta.url}/lese`}>
                    Åpne boka
                </AButton>
                {activeCover < books.length - 1 ? (
                    <AButton
                        center
                        variant="secondary"
                        overrideColor={theme.palette.common.white}
                        startIcon={<IconChevronRight color={theme.palette.common.white} size={20} />}
                        sx={{
                            p: 0,
                            minWidth: 'auto',
                            width: theme.spacing(10),
                            '& .MuiButton-startIcon': { mr: 0 },
                        }}
                        onClick={() => {
                            setActiveCover(activeCover + 1);
                        }}
                    />
                ) : (
                    <Box sx={{ width: theme.spacing(10) }} />
                )}
            </Stack>
        </Stack>
    );
};

export default CoverFlow;
