import { AButton } from '@allvit/allvit-toolkit';
import { Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from '@remix-run/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Image from '~/components/atoms/Image';
import { InfoModal } from '~/components/organisms/InfoModal';
import globalConfig from '~/configs/global';
import BookTypes from '~/types/app/book';
import { getBookImageUrl } from '~/utils/book';
import { setFunctionalCookieValue } from '~/utils/cookies';

export const SubscriptionExpiringModal = ({
    setShowSubscriptionModal,
    usersSubscriptionBooks,
}: {
    setShowSubscriptionModal: Dispatch<SetStateAction<boolean>>;
    usersSubscriptionBooks: Array<BookTypes>;
}): JSX.Element => {
    const [bookCovers, setBookCovers] = useState<Array<string>>([]);
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        if (usersSubscriptionBooks && usersSubscriptionBooks.length > 0) {
            const coverUrlsUnsorted = usersSubscriptionBooks
                .filter((book) => getBookImageUrl(book) !== '')
                .map((book) => getBookImageUrl(book));
            const coverUrlsSorted = [coverUrlsUnsorted[0]];
            for (const [index, url] of coverUrlsUnsorted.entries()) {
                if (index !== 0) {
                    if (index % 2 === 0) {
                        coverUrlsSorted.push(url);
                    } else {
                        coverUrlsSorted.unshift(url);
                    }
                }
            }
            setBookCovers(Array.from(new Set(coverUrlsSorted)));
        }
    }, []);

    return (
        <InfoModal
            open
            handleCloseModal={() => {
                setFunctionalCookieValue('showSubscriptionModal', {
                    value: false,
                    expires: Date.now() + globalConfig.subscriptionModalCookieExpiryTime,
                });
                setShowSubscriptionModal(false);
            }}
            title="Abonnementet ditt utløper snart! ⚠️"
            sections={[
                {
                    content: (
                        <Typography component="span" sx={{ mt: 2 }}>
                            Når abonnementet ditt utløper vil du miste tilgangen til alle bøkene dine som er inkludert i
                            Allvit+.
                        </Typography>
                    ),
                    containerSx: { mb: 2 },
                },
                {
                    content: (
                        <>
                            {bookCovers.length > 0 && (
                                <Stack
                                    sx={{
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        maskImage:
                                            'linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(255, 0, 0, 1), rgba(0, 0, 0, 0.1))',
                                    }}
                                >
                                    {bookCovers.map((cover, index) => (
                                        <Image
                                            key={index}
                                            src={cover}
                                            alt=""
                                            sx={{
                                                height: '100px',
                                                width: '80px',
                                                mr: 2,
                                            }}
                                        />
                                    ))}
                                </Stack>
                            )}
                        </>
                    ),
                    containerSx: { mb: 2 },
                },
                {
                    content: (
                        <Typography>
                            Vi setter pris på deg og har derfor ordnet en god deal, slik at du ikke trenger å blakke deg
                            for å fortsette å ha tilgang til bøkene du trenger 👇🏼
                        </Typography>
                    ),
                    containerSx: { mb: 4 },
                },
                {
                    title: 'Forny Allvit+ gratis 🤩',
                    content: (
                        <Typography sx={{ mt: 2 }}>
                            Verv tre venner og få Allvit+ gratis i 3 måneder. Hver venn du verver får <b>25% rabatt</b>{' '}
                            på de tre første månedene av abonnementet. En bra deal for deg og vennene dine!
                        </Typography>
                    ),
                    button: (
                        <AButton
                            variant="primary"
                            sx={{ width: 'fit-content' }}
                            onClick={() => {
                                navigate('/profil/verve');
                            }}
                        >
                            Start vervingen her!
                        </AButton>
                    ),
                },
                {
                    title: 'Forny Allvit+ nå og få 50% rabatt!',
                    content: (
                        <Typography sx={{ mt: 2 }}>
                            Vi gir deg 50% rabatt på ordinærpris dersom du fornyer abonnementet ditt nå.
                        </Typography>
                    ),
                    button: (
                        <AButton
                            variant="primary"
                            sx={{ width: 'fit-content' }}
                            onClick={() => {
                                navigate('/start-allvit-pluss');
                            }}
                        >
                            Forny Allvit+
                        </AButton>
                    ),
                },
            ]}
            modalSx={{ overflow: 'scroll' }}
            sx={{
                overflow: 'hidden',
                [theme.breakpoints.up('sm')]: { minWidth: '550px' },
            }}
            showBottomCloseButton
            showTopCloseButton
        />
    );
};
